import React, { useState } from "react";
import "../Pages/Pages.css";
import { Container, Row } from "react-bootstrap";
import FsLightbox from "fslightbox-react";

import { womens } from "./constants/womensImages";

export default function WomenProducts() {
  const [toggler, setToggler] = useState(false);
  return (
    <div>
      <div className="mens_header_bg">
        <h3>Womens Products</h3>
      </div>
      <div className="womens_content">
        <Container>
          <Row>
            {womens.map((item, index) => (
              <div className="col-md-3 col-sm-3 col-6">
                <div className="card my-3">
                  <div className="card-body">
                    <a
                      className="gallery_img"
                      onClick={() => setToggler(!toggler)}
                    >
                      <img
                        className="img-fluid"
                        key={index}
                        src={item.image}
                        alt="jnc_womens"
                      />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Row>
          <FsLightbox
            toggler={toggler}
            sources={womens.map((item) => {
              return item.image;
            })}
          />
        </Container>
      </div>
    </div>
  );
}
