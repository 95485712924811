import React from "react";
import "../Pages/Pages.css";
import { Container, Row } from "react-bootstrap";
export default function CompanyProfile() {
  return (
    <div>
      <div className="company_profile">
        <Container>
          <Row>
            <div className="col-md-4 col-sm-4 col-12">
              <div className="company_overview">
                <h3>Company Overview</h3>
              </div>
            </div>
            <div className="col-md-8 col-sm-8 col-12">
              <p>
                <b>Comapany Name:</b> STYLEBAY.
              </p>
              <p>
                <b>Type of Company:</b> Private Limited Company
              </p>
              <p>
                <b>Activities of the company:</b> Trading House/ Buying House
                /Exporter & Importer.
              </p>
              <p>
                <b>Legitimacy of the company:</b> Registered By Joint Stock
                Corporation, Dhaka City Corporation, Bangladesh Garments
                Manufacturer Exporter Association (BGMEA), Bangladesh Garments
                Buying House Association (BGBA), Bangladesh Textile & Mills
                Corporation (BTMC).
              </p>
              <p>
                <b>Legal Identity:</b> STYLEBAY.
              </p>
              <p>
                <b>Trade License No:</b>
              </p>
              <p>
                <b>Group CEO:</b> Muhammad Bashir Uddin
              </p>
              <p>
                <b>Employee & Management:</b> 5 person.
              </p>
              <p>
                <b>Yearly Turnover:</b> 20 MILLION USD.
              </p>
              <p>
                <b>Establishment:</b> 2018 , Dhaka , Bangladesh.
              </p>
              <p>
                <b>Products:</b> STYLEBAY is exporting all kinds of products for
                men, women and kids. Product range: T-Shirts (Long sleeve, short
                sleeve), Polo Shirts (Long sleeve, short sleeve), Tank Tops,
                Sweat Shirts, Jogging Suits, Skirts, Trousers, Shorts, Shirts,
                Pants, Underwear and sweater.
              </p>
              <p>
                <b>Capacity:</b> Knit 60-90 days after confirmation woven 90-120
                days after confirmation Sweater 120-130 days after confirmation.
                For the repeat orders, we need 45 days lead time or it is
                negotiable during the order placement time.
              </p>
              <p>
                <b>Production Facility:</b> By associated with 30
                full-complienced export oriented manufactories in Bangladesh.
              </p>
              <p>
                <b>Production follow up:</b> We have a good setup to execute the
                order properly and ensuring the on time delivery with good
                quality. Production is followed-up daily by our qualified team
                of Merchandisers who carefully take care the status of raw
                materials, accessories, knitting, dyeing, sewing etc. Quality
                team followed-up the production at factory ground and ensure the
                quality and they communicate with the merchandising team for
                each and every issues.
              </p>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}
