import React from "react";
import "../Pages/Pages.css";
import { Container, Row } from "react-bootstrap";
export default function Vision() {
  return (
    <div>
      <div className="company_profile_header_bg">
        <h3>Vision and Mission</h3>
      </div>
      <div className="company_vision">
        <Container>
          <Row>
            <div className="offset-md-6 col-md-6 col-sm-6 col-12">
              <h3 style={{ color: "#0070f3" }}>Vision</h3>
              <p>
                Our vision is simple but bold we aspire to maintain the image of
                the brands to help our customers navigate their own distinct
                identity, styles and continuos stretagic planning for better
                productions.
              </p>
            </div>
          </Row>
          <Row>
            <div className="col-md-6 col-sm-6 col-12 offset-md-6">
              <h3 style={{ color: "#0070f3" }}>Mission</h3>
              <b>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-right-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h3.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z" />
                </svg>
              </b>{" "}
              To strive hard to be a trading house of world class textile &
              garments products as well as service while positioning the country
              in the higher value segment of the international textile market.{" "}
              <br />
              <br />
              <b>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-right-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h3.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z" />
                </svg>
              </b>{" "}
              Our Relentless endeavor is contributing to enhancing the country’s
              position as an international provider of quality products. <br />
              <br />
              <b>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-right-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h3.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z" />
                </svg>
              </b>{" "}
              To satisfy customers through technology and synergic
              synchronization of man and machine , tailoring quality products
              and services to harvest the reward of responsibility. <br />
              <br />
              <b>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-right-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h3.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z" />
                </svg>
              </b>{" "}
              To Create a better future for the stakeholders, maintaining high
              standards of integrity, propriety and goodwill in shouldering
              social responsibilities.
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}
