export const tescos = [
  { image: "/images/tesco/1.jpg" },
  { image: "/images/tesco/2.jpg" },
  { image: "/images/tesco/3.jpg" },
  { image: "/images/tesco/4.jpg" },
  { image: "/images/tesco/5.jpg" },
  { image: "/images/tesco/6.jpg" },
  { image: "/images/tesco/7.jpg" },
  { image: "/images/tesco/8.jpg" },
  { image: "/images/tesco/9.jpg" },
  { image: "/images/tesco/10.jpg" },
  { image: "/images/tesco/11.jpg" },
  { image: "/images/tesco/12.jpg" },
  { image: "/images/tesco/13.jpg" },
];
