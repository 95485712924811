import React from "react";
import "./Navigation.css";
import { NavDropdown, Container, Navbar, Nav } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";

import "react-bootstrap-submenu/dist/index.css";

import { Link } from "react-router-dom";
import jncLogo from "../../../assets/images/jnclogo.png";

export default function Navigation() {
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        // fixed="top"
        className="shadow-lg"
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            {/* <img className="img-fluid brand-logo" src={jncLogo} alt="" /> */}
            <h2 className="logo">Stylebay</h2>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              {/* About Dropdown */}
              <NavDropdown
                title="About"
                id="collasible-nav-dropdown"
                menuVariant="light"
              >
                <NavDropdown.Item as={Link} to="/company-profile">
                  Company Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/vision">
                  Vision
                </NavDropdown.Item>
                {/* <NavDropdown.Item as={Link} to="/management">
                  Our Management
                </NavDropdown.Item> */}
              </NavDropdown>
              <Nav.Link as={Link} to="/services">
                Our Services
              </Nav.Link>
              {/* Products Dropdown */}
              <NavDropdownMenu
                title="Products Gallery"
                id="collasible-nav-dropdown"
                menuVariant="light"
              >
                <DropdownSubmenu title="Key Clients">
                  <NavDropdown.Item as={Link} to="/us-polo">
                    US Polo
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/rich-cotton">
                    Rich Cotton
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/emart">
                    Emart
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/head">
                    Head
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/tesco">
                    Tesco
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/eddiebauer">
                    Eddie Bauer
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/forever">
                    Forever 21
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/skechers">
                    Skechers
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/keithhering">
                    Keith Hering
                  </NavDropdown.Item>
                </DropdownSubmenu>
                <NavDropdown.Item as={Link} to="/mens">
                  Mens
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/womens">
                  Womens
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/kids">
                  Kids
                </NavDropdown.Item>
              </NavDropdownMenu>
              <Nav.Link as={Link} to="/quality">
                Quality
              </Nav.Link>
              <Nav.Link as={Link} to="/compliance">
                Compliance
              </Nav.Link>
              <Nav.Link as={Link} to="/contact">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
