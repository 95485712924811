export const certificates = [
  { image: "/images/certificatelogos/accord-logo.png" },
  { image: "/images/certificatelogos/bsci-logo.png" },
  { image: "/images/certificatelogos/cit-logo.png" },
  { image: "/images/certificatelogos/ctpat.jpg" },
  { image: "/images/certificatelogos/gots-log.png" },
  { image: "/images/certificatelogos/ocs-logo.jpg" },
  { image: "/images/certificatelogos/rcs-logo.jpg" },
  { image: "/images/certificatelogos/sedex-logo.png" },
  { image: "/images/certificatelogos/sgs-logo.png" },
  { image: "/images/certificatelogos/wrap-logo.png" },
];

// import accord from "../assets/images/certificatelogos/accord-logo.png";
// import bsci from "../assets/images/certificatelogos/bsci-logo.png";
// import cit from "../assets/images/certificatelogos/cit-logo.png";
// import ctpat from "../assets/images/certificatelogos/ctpat.jpg";
// import gots from "../assets/images/certificatelogos/gots-log.png";
// import ocs from "../assets/images/certificatelogos/ocs-logo.jpg";
// import rcs from "../assets/images/certificatelogos/rcs-logo.jpg";
// import sedex from "../assets/images/certificatelogos/sedex-logo.png";
// import sgs from "../assets/images/certificatelogos/sgs-logo.png";
// import wrap from "../assets/images/certificatelogos/wrap-logo.png";
