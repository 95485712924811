import React, { useEffect, useState } from "react";
import "./Pages.css";
import AOS from "aos";
import { Container, Row } from "react-bootstrap";
import HeroSlider from "../components/HeroSlider/HeroSlider";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

import aboutCompany from "../assets/images/about_bg_img.jpg";
import { certificates } from "../components/constants/certificatelogo";
import { buyerlogos } from "../components/constants/buyerlogo";

export default function HomePage() {
  const [counterOn, setCounterOn] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  }, []);
  return (
    <div>
      <HeroSlider />
      <section className="about_company">
        <Container>
          <Row>
            <div className="col-md-4 col-sm-4 col-12">
              <div data-aos="zoom-in" className="about_wrapper">
                <img className="img-fluid" src={aboutCompany} alt="" />
              </div>
            </div>
            <div className="col-md-8 col-sm-8 col-12">
              <div className="about_content">
                <h2 data-aos="fade-up">Who we are?</h2>
                <p data-aos="fade-left">
                  <strong>Styelebay</strong> is started their buying business
                  since 2018 in South Asia-Bangladesh & operating their work as
                  buying bridge between buyers/importer & manufacturer/ Exporter
                  for readymade all kinds of garments product . We have a strong
                  co-relation with Bangladeshi renowned garments manufacturer
                  where we do the production of our buyers products by strict
                  order follow up with keeping quality assurances and standard
                  inspections as well as on time deliveries .
                </p>
                <p data-aos="fade-right">
                  We have been set up global merchandise team to provide
                  sourcing services to buyer across the global market for
                  finished apparels and textiles as well as ready made garments
                  stock lot from Bangladesh. our mission has been strive for the
                  greatest possible satisfactions & mutual benefits for our
                  customers & manufacturer as we concentrate on quality, on–time
                  delivery and the best possible value for money at all times.
                </p>
                <p data-aos="fade-left">
                  A combination of competitive prices, good quality and on time
                  delivery is our main strength. Our services included -Product
                  development, Supplier sourcing, Production Planning &
                  follow-up, Merchandising, Monitoring all kinds of supply chain
                  , Factory audits , Quality assurance etc .
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <section className="approved_certificate">
        <Container>
          <Row>
            <div className="col-md-8">
              <div className="certificate_wrapper">
                <Row>
                  {certificates.map((item, index) => (
                    <div data-aos="fade-up" className="col-md-3 col-6">
                      <div
                        className="card my-3"
                        style={{ height: "140px", width: "100%" }}
                      >
                        <div className="card-body">
                          <img
                            style={{
                              height: "120px",
                              width: "100%",
                              padding: "8px",
                            }}
                            className="img-fluid"
                            key={index}
                            src={item.image}
                            alt="certificates-logo"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Row>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-12">
              <div data-aos="flip-left" className="content_wrapper">
                <h2>
                  Our assigned manufactories are approved for these global
                  certifications/standards
                </h2>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <section className="creative_message_section">
        <Container>
          <div className="creative_message_top">
            <h2 data-aos="fade-up">We do creative & Trendy fashion clothing</h2>
            <h6 data-aos="fade-down">
              Clothing Manufacturer Providing healthy services over years,we
              manufacture & export Baby Clothes, Kids Clothes, Women’s Clothing,
              Men's Clothing, T-Shirt, Polo Shirts, Hoodies, Sweatshirts, Pant,
              Shorts, Skirts, Dresses, Sportswear, School Uniforms, Sports
              Uniforms, Corporate Uniforms & Custom Clothing.
            </h6>
          </div>
          <Row>
            <div className="col-md-3">
              <div data-aos="zoom-in-down" className="creative_message">
                <h5>Sampling lead-time in days</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div data-aos="zoom-in-down" className="creative_message">
                <h5>
                  Production Lead time (Its depends on the styling, quantity)
                </h5>
              </div>
            </div>
            <div className="col-md-3">
              <div data-aos="zoom-in-down" className="creative_message">
                <h5>MOQ Pieces(Per Style, Colour, Design)</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div data-aos="zoom-in-down" className="creative_message">
                <h5>Percent Satisfaction</h5>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      {/* Home Page Counter Section */}
      <section className="counter_section">
        <Container>
          <Row>
            <div className="counter_title">
              <h1 className="mb-5">BUSINESS WE DEALT SO FAR</h1>
            </div>
            <div className="counter_content">
              <Row>
                <div data-aos="fade-up" className="col-md-3 col-12">
                  <ScrollTrigger
                    onEnter={() => setCounterOn(true)}
                    onExit={() => setCounterOn(false)}
                  >
                    <h1>
                      {counterOn && <CountUp end={100} duration={3}></CountUp>}
                    </h1>
                  </ScrollTrigger>
                  <h2>Catagories</h2>
                  <h5>Available</h5>
                </div>
                <div data-aos="fade-up" className="col-md-3 col-12">
                  <ScrollTrigger
                    onEnter={() => setCounterOn(true)}
                    onExit={() => setCounterOn(false)}
                  >
                    <h1>
                      {counterOn && <CountUp end={5} duration={2.75}></CountUp>}
                    </h1>
                  </ScrollTrigger>
                  <h1>Years</h1>
                  <h5>Of Experience</h5>
                </div>
                <div data-aos="fade-up" className="col-md-3 col-12">
                  <ScrollTrigger
                    onEnter={() => setCounterOn(true)}
                    onExit={() => setCounterOn(false)}
                  >
                    <h1>
                      {counterOn && (
                        <CountUp end={230} duration={2.75}></CountUp>
                      )}
                    </h1>
                  </ScrollTrigger>
                  <h1>Assigned</h1>
                  <h5>Manufacturing Units</h5>
                </div>
                <div data-aos="fade-up" className="col-md-3 col-12">
                  <ScrollTrigger
                    onEnter={() => setCounterOn(true)}
                    onExit={() => setCounterOn(false)}
                  >
                    <h1>
                      {counterOn && (
                        <CountUp end={17} duration={2.75}></CountUp>
                      )}
                    </h1>
                  </ScrollTrigger>
                  <h1>Associated</h1>
                  <h5>Countries</h5>
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </section>
      {/* Home Page Client Logo Section */}
      <section className="our_client">
        <div className="our_client_title">
          <h3>OUR CLIENTS, BRANDS, CUSTOMERS- AROUND THE WORLD</h3>
        </div>
        <Container>
          <Row>
            {buyerlogos.map((item, index) => (
              <div data-aos="fade-up" className="col-md-3 col-6">
                <div className="my-3">
                  <div className="img_wrapper">
                    <img
                      style={{ height: "120px" }}
                      className="img-fluid"
                      key={index}
                      src={item.image}
                      alt="buyer-logo"
                    />
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
}
