export const uspolos = [
  { image: "/images/uspolo/1.jpg" },
  { image: "/images/uspolo/2.jpg" },
  { image: "/images/uspolo/3.jpg" },
  { image: "/images/uspolo/4.jpg" },
  { image: "/images/uspolo/5.jpg" },
  { image: "/images/uspolo/6.jpg" },
  { image: "/images/uspolo/7.jpg" },
  { image: "/images/uspolo/8.jpg" },
  { image: "/images/uspolo/9.jpg" },
];
