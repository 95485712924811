export const mens = [
  { image: "/images/mens/1.jpg" },
  { image: "/images/mens/2.jpg" },
  { image: "/images/mens/3.jpg" },
  { image: "/images/mens/4.jpg" },
  { image: "/images/mens/5.jpg" },
  { image: "/images/mens/6.jpg" },
  { image: "/images/mens/7.jpg" },
  { image: "/images/mens/8.jpg" },
  { image: "/images/mens/9.jpg" },
  { image: "/images/mens/10.jpg" },
  { image: "/images/mens/11.jpg" },
  { image: "/images/mens/12.jpg" },
  { image: "/images/mens/13.jpg" },
  { image: "/images/mens/14.jpg" },
  { image: "/images/mens/15.jpg" },
  { image: "/images/mens/16.jpg" },
  { image: "/images/mens/17.jpg" },
  { image: "/images/mens/18.jpg" },
  { image: "/images/mens/19.jpg" },
  { image: "/images/mens/20.jpg" },
  { image: "/images/mens/21.jpg" },
  { image: "/images/mens/22.jpg" },
  { image: "/images/mens/23.jpg" },
  { image: "/images/mens/24.jpg" },
  { image: "/images/mens/25.jpg" },
  { image: "/images/mens/26.jpg" },
  { image: "/images/mens/27.jpg" },
  { image: "/images/mens/28.jpg" },
  { image: "/images/mens/29.jpg" },
  { image: "/images/mens/30.jpg" },
  { image: "/images/mens/31.jpg" },
  { image: "/images/mens/32.jpg" },
  { image: "/images/mens/33.jpg" },
  { image: "/images/mens/34.jpg" },
  { image: "/images/mens/35.jpg" },
  { image: "/images/mens/36.jpg" },
  { image: "/images/mens/37.jpg" },
  { image: "/images/mens/38.jpg" },
  { image: "/images/mens/39.jpg" },
  { image: "/images/mens/40.jpg" },
  { image: "/images/mens/41.jpg" },
  { image: "/images/mens/42.jpg" },
  { image: "/images/mens/43.jpg" },
  { image: "/images/mens/44.jpg" },
  { image: "/images/mens/45.jpg" },
  { image: "/images/mens/46.jpg" },
  { image: "/images/mens/47.jpg" },
  { image: "/images/mens/48.jpg" },
  { image: "/images/mens/49.jpg" },
  { image: "/images/mens/50.jpg" },
  { image: "/images/mens/51.jpg" },
  { image: "/images/mens/52.jpg" },
  { image: "/images/mens/53.jpg" },
  { image: "/images/mens/54.jpg" },
  { image: "/images/mens/55.jpg" },
  { image: "/images/mens/56.jpg" },
  { image: "/images/mens/57.jpg" },
  { image: "/images/mens/58.jpg" },
  { image: "/images/mens/59.jpg" },
  { image: "/images/mens/60.jpg" },
  { image: "/images/mens/61.jpg" },
  { image: "/images/mens/62.jpg" },
  { image: "/images/mens/63.jpg" },
  { image: "/images/mens/64.jpg" },
  { image: "/images/mens/65.jpg" },
  { image: "/images/mens/66.jpg" },
  { image: "/images/mens/67.jpg" },
  { image: "/images/mens/68.jpg" },
  { image: "/images/mens/69.jpg" },
  { image: "/images/mens/70.jpg" },
  { image: "/images/mens/71.jpg" },
  { image: "/images/mens/72.jpg" },
  { image: "/images/mens/73.jpg" },
  { image: "/images/mens/74.jpg" },
  { image: "/images/mens/75.jpg" },
  { image: "/images/mens/76.jpg" },
  { image: "/images/mens/77.jpg" },
  { image: "/images/mens/78.jpg" },
  { image: "/images/mens/79.jpg" },
  { image: "/images/mens/80.jpg" },
  { image: "/images/mens/81.jpg" },
  { image: "/images/mens/82.jpg" },
  { image: "/images/mens/83.jpg" },
  { image: "/images/mens/84.jpg" },
  { image: "/images/mens/85.jpg" },
  { image: "/images/mens/86.jpg" },
  { image: "/images/mens/87.jpg" },
  { image: "/images/mens/88.jpg" },
  { image: "/images/mens/89.jpg" },
  { image: "/images/mens/90.jpg" },
  { image: "/images/mens/91.jpg" },
  { image: "/images/mens/92.jpg" },
  { image: "/images/mens/93.jpg" },
  { image: "/images/mens/94.jpg" },
  { image: "/images/mens/95.jpg" },
  { image: "/images/mens/96.jpg" },
  { image: "/images/mens/97.jpg" },
  { image: "/images/mens/98.jpg" },
  { image: "/images/mens/99.jpg" },
  { image: "/images/mens/100.jpg" },
  { image: "/images/mens/101.jpg" },
  { image: "/images/mens/102.jpg" },
  { image: "/images/mens/103.jpg" },
  { image: "/images/mens/104.jpg" },
  { image: "/images/mens/105.jpg" },
  { image: "/images/mens/106.jpg" },
  { image: "/images/mens/107.jpg" },
  { image: "/images/mens/108.jpg" },
  { image: "/images/mens/109.jpg" },
  { image: "/images/mens/110.jpg" },
  { image: "/images/mens/111.jpg" },
  { image: "/images/mens/112.jpg" },
  { image: "/images/mens/113.jpg" },
  { image: "/images/mens/114.jpg" },
  { image: "/images/mens/115.jpg" },
  { image: "/images/mens/116.jpg" },
  { image: "/images/mens/117.jpg" },
  { image: "/images/mens/118.jpg" },
  { image: "/images/mens/119.jpg" },
  { image: "/images/mens/120.jpg" },
];
