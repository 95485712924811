export const buyerlogos = [
  { image: "/images/buyer/eddies-bauer.png" },
  { image: "/images/buyer/emart.png" },
  { image: "/images/buyer/forever-21.jpg" },
  { image: "/images/buyer/guess.png" },
  { image: "/images/buyer/hard-rock.png" },
  { image: "/images/buyer/head.png" },
  { image: "/images/buyer/home-plus.jpg" },
  { image: "/images/buyer/joy-rich.jpg" },
  { image: "/images/buyer/keith-haring.jpg" },
  { image: "/images/buyer/rich-cotton.jpg" },
  { image: "/images/buyer/skechers.jpg" },
  { image: "/images/buyer/us-polo.jpg" },
];

// import eddiesBauer from "../assets/images/buyer/eddies-bauer.png";
// import emart from "../assets/images/buyer/emart.png";
// import forever from "../assets/images/buyer/forever-21.jpg";
// import guess from "../assets/images/buyer/guess.png";
// import hardRock from "../assets/images/buyer/hard-rock.png";
// import head from "../assets/images/buyer/head.png";
// import homePlus from "../assets/images/buyer/home-plus.jpg";
// import joyRich from "../assets/images/buyer/joy-rich.jpg";
// import keithHaring from "../assets/images/buyer/keith-haring.jpg";
// import richCotton from "../assets/images/buyer/rich-cotton.jpg";
// import skechers from "../assets/images/buyer/skechers.jpg";
// import usPolo from "../assets/images/buyer/us-polo.jpg";
