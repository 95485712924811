import React from "react";
import "../Pages/Pages.css";
import { Accordion, Container, Row } from "react-bootstrap";

import serviceImg from "../assets/images/services.png";

export default function Services() {
  return (
    <div>
      <div className="service_bg">
        <h3>Our Services</h3>
      </div>
      <div className="service_content">
        <Container>
          <Row>
            <div className="col-md-6 col-sm-6 col-12">
              <div className="service_wrapper">
                <p>
                  STYLEBAY is committed to provide you best customer service and
                  guaranteed for the best quality products at a very competitive
                  price. Our comprehensive services are not only limited to the
                  best sourcing house, buying arrangement, meeting with best
                  manufacturer in the industry, sample development, sample
                  approval, production quality control and timely shipment but
                  also we take more ownership and responsibilities on our
                  shoulders on your behalf in order to make sure risks are
                  minimized and quality of products are not compromised.
                </p>
                <img className="img-fluid" src={serviceImg} alt="" />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <h2>Key Functions</h2>
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Sourcing</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      We have more vast experience in Asia under our belt ,
                      during this period we have developed real partnerships
                      with our South-Asian vendor networks-
                    </p>
                    <ul>
                      <li>
                        We have worked with some of our vendors for more than
                        3-4 years , we regard them as the backbone of our
                        business.
                      </li>
                      <li>
                        Since buying streams are changing constantly , our
                        product manager work non-stop to open up new and
                        promising sources .
                      </li>
                    </ul>
                    <p>
                      As a service company pure and simple , we are flexible
                      enough to look continuously for the best suppliers –
                      throughout the Asian region.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Product Development</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Price is only one aspect of competition , increasingly ,
                      differentiation and creativity are providing to be major
                      advantages for our customer base . In cooperation with our
                      vendors , we carve ourselves a distinctive position with
                      new & unique products-
                    </p>
                    <ul>
                      <li>
                        We cooperate with independent , innovative product
                        designers in the fields of high-end goods and textiles
                        for enhancing our creative knowledges, up-to-date
                        fashion trends.
                      </li>
                      <li>
                        We have the advantages of knowing what the market needs
                        and can transfer that knowledge from the most creative
                        market.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Merchandising</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Our day-to-day communication with customers and suppliers
                      gives us a profound understanding of what our customers
                      demand -
                    </p>
                    <ul>
                      <li>
                        Our skilled specialists monitor and guide sampling and
                        production process.
                      </li>
                      <li>
                        Our specialist staff monitor the safety aspects or our
                        product.
                      </li>
                      <li>
                        Technical, functional support in sampling & pattern.
                      </li>
                      <li>Research & Development for stepping forward.</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Manufactory Selection & Evaluation
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      We believe in ''Right place for right operations'' and for
                      that we researching for the accurate manufactories for
                      accurate order in terms of that we consider line setup,
                      pros & cons & other derivatives for efficient output.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Quality</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Quality is the key to our business as we understand it .
                      It encompasses communication and service quality as well
                      as product planning and product quality assurance . There
                      are several measures that we implement to guarantee
                      high-quality products -
                    </p>
                    <ul>
                      <li>Initial production check</li>
                      <li>During production check</li>
                      <li>Final random inspection</li>
                      <li>Loading supervision</li>
                      <li>AQL-General explanation.</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Production Planning & TNA</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        We plan and propose suppliers timeframes to customers.
                      </li>
                      <li>
                        We properly maintain lead time & in-housing, action
                        planning of raw materials, sewing materials, fabrics,
                        finishing accessories.
                      </li>
                      <li>
                        Our team maintain buyer's delivery schedule, deadline by
                        following TNA along with factories.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    Logistics Support & co-operation
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        We plan and prepare all kinds of shipping derivatives,
                        global logistics supports according to buyer
                        requirements.
                      </li>
                      <li>
                        Partially, we are too much concerned about negotiating,
                        find out competitive shipping prices for global
                        shipping.
                      </li>
                      <li>
                        Our specific logistics team monitoring, controlling
                        products ETA, EDA based on shipping line weekly vessel
                        schedules.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    Stylebay Working Procedures
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Factory Audit –Prerequisite for Successful Sourcing
                      Stylebay WORKING PROCEDURES Selecting the right vendor is
                      one of the most important preconditions for succes sful
                      sourcing. Factory audit helpsto verify a manufacturers
                      abilityto comply with contractual terms in respectof
                      quality , quantity and delivery . Its assessment pr
                      ocedures can be tailoredto the customer’s need and
                      requirements .
                    </p>
                    <p>
                      The number of countries where consumer goodsare
                      manufactured in increasing in line with the accelerating
                      paceof trade globalization .To select the right global
                      supplier , we need an on side presence to monitor the
                      production process. In additional to production capacity,
                      many others aspect have to be taken into consideration –
                    </p>
                    <h3>Benefits</h3>
                    <ul>
                      <li>
                        Increase confidence that the manufacturer can do better
                        service.
                      </li>
                      <li>
                        Requirements regarding quality , quantity and terms of
                        delivery.
                      </li>
                      <li>Reduction in import risks.</li>
                      <li>No need for customer on-site presence.</li>
                    </ul>
                    <h3>Stylebay can offers-</h3>
                    <ul>
                      <li>First prototype production.</li>
                      <li>Technical documentation.</li>
                      <li>Evaluation of standards.</li>
                      <li>Establishment of production and quality criteria.</li>
                      <li>
                        Technology transfer to the supplier of pattern
                        corrections.
                      </li>
                      <li>CAD DESIGNING</li>
                      <li>
                        During production Check – Confirm product compliance
                        during production.
                      </li>
                    </ul>
                    <p>
                      The during production check ( DUPRO) is a visual
                      inspection of products as available during production. The
                      during- production check is offered in combination with
                      the final random inspection and other ongoing production
                      checks such as the initial production check. The execution
                      of the purchase order is also monitored closely as part of
                      this multi-stage inspection. The resultant interim reports
                      keep you informed about production progress in relation to
                      the terms of delivery.
                    </p>
                    <p>
                      The production process must be monitored on-site risks of
                      substandard quality are to be reduced and other aspects of
                      contractual obligation satisfied. The earlier any possible
                      errors in execution are detected, the more likely that
                      timely corrective action can be initiated.
                    </p>
                    <h3>Benefits</h3>
                    <ul>
                      <li>
                        Reduction of final random inspection failure risk.
                      </li>
                      <li>Identification of possible delays.</li>
                      <li>
                        Take corrective action before production is finished.
                      </li>
                      <li>No need for the client to be on-the-spot.</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>Stylebay can offers-</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>First prototype production.</li>
                      <li>Technical documentation.</li>
                      <li>Evaluation of standards.</li>
                      <li>Establishment of production and quality criteria.</li>
                      <li>
                        Technology transfer to the supplier of pattern
                        corrections.
                      </li>
                      <li>CAD DESIGNING</li>
                      <li>
                        During production Check – Confirm product compliance
                        during production.
                      </li>
                    </ul>
                    <p>
                      The during production check ( DUPRO) is a visual
                      inspection of products as available during production. The
                      during- production check is offered in combination with
                      the final random inspection and other ongoing production
                      checks such as the initial production check. The execution
                      of the purchase order is also monitored closely as part of
                      this multi-stage inspection. The resultant interim reports
                      keep you informed about production progress in relation to
                      the terms of delivery.
                    </p>
                    <p>
                      The production process must be monitored on-site risks of
                      substandard quality are to be reduced and other aspects of
                      contractual obligation satisfied. The earlier any possible
                      errors in execution are detected, the more likely that
                      timely corrective action can be initiated.
                    </p>
                    <h3>Benefits</h3>
                    <ul>
                      <li>
                        Reduction of final random inspection failure risk.
                      </li>
                      <li>Identification of possible delays.</li>
                      <li>
                        Take corrective action before production is finished.
                      </li>
                      <li>No need for the client to be on-the-spot.</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    Final Random Inspection – Check & Sampling
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      The Final Random Inspection is the detailed visual
                      inspection of goods before shipment as per buyer AQL . It
                      is generally conducted on the manufacturer's premises,
                      using samples selected in accordance with defined sampling
                      procedures. The inspection criteria covering quality,
                      quantity, marking, and packing is based on specifications
                      and reference samples provided by the customer. When the
                      final Ra random inspection has been completed, a detailed
                      inspection report is sent to the customer and the
                      inspection certificate is issuedto the supplier if all the
                      findings are positive
                    </p>
                    <p>
                      As trade globalization gains peace, sourcing from faraway
                      countries and new supplier increase, under these
                      conditions, monitoring the production process equip red
                      on-the-spot presence to reduce the risk of substandard
                      quality, discrepancies, shipment delays, and other
                      contraventions against purchase order requirements –
                    </p>
                    <h3>Benefits</h3>
                    <ul>
                      <li>
                        can meet the quality standard of worlds renowned
                        institutes,
                      </li>
                      <li>Tailored to your needs,</li>
                      <li>Reduction in import risk,</li>
                      <li>
                        Improved management and control of overseas sourcing,
                      </li>
                      <li>no need for the customer's on-site presence,</li>
                      <li>
                        crosslink international inspection certified issued.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    Testing Coordination – Prerequisite for successful product
                    development.
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Do you want to make sure that the product which you
                      purchaseis up to contractual specific actions as wellas
                      the legal requirements of the importing country? The test
                      report givesyo u the detailsof every points wherethe
                      product passor fail , together witha summary . This simple
                      tool enablesyou to communicate with your supplier without
                      having to be a qualified engineer.
                    </p>
                    <h5>Textile</h5>
                    <p>
                      These tests areconducted to assestheir conformity with
                      mandatory requirements . Tests include ISO , BV, SGS and
                      Oeko-Tex standards
                    </p>
                    <h5>Packaging and paper</h5>
                    <p>
                      Various test are conducted in accordance withthe ISO ,
                      ASRM, TAPPI , NSTA, BS and JIS standard
                    </p>
                    <h5>Environmental and Chemical</h5>
                    <p>
                      These tests are conducted to assess conformity with
                      environmental andchemical requireme nts . These days,with
                      ecological concerns growing all the time , consumers want
                      goods that are free of toxicor cancer causingcontent such
                      as PAH , cadmiumetc .
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}
